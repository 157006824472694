:root {
	--ecomm-cart-summary-link-colour: #9E7C10;
}

.breadcrumb {
    color: var(--ecomm-cart-summary-link-colour);

    a {
        color: var(--ecomm-cart-summary-link-colour);
    }

    a:visited {
        color: var(--ecomm-cart-summary-link-colour);
    }
}