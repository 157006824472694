:root {
  --ecomm-order-summary-colour: #{$msv-white};

  --ecomm-order-summary-toggle-border: 1px solid #{$msv-white};
  --ecomm-order-summary-toggle-colour: #{$msv-white};

  --ecomm-order-summary-action-border: 1px solid #{$msv-white};
	--ecomm-order-summary-action-colour: #{$msv-white};

	--ecomm-cart-summary-bg: #{$msv-bleach-white};

  --ecomm-order-summary-divider-border: 1px solid #{$msv-white};

	--ecomm-cart-summary-btn-alt-bg: var(--brandPrimaryColor);
	--ecomm-cart-summary-btn-alt-colour: var(--brandTextColour);
	--ecomm-cart-summary-btn-border: 1px solid var(--brandTextColour);
	--ecomm-cart-summary-btn-colour: var(--brandPrimaryColor);
	--ecomm-cart-summary-link-colour: var(--brandTextColour);
  --ecomm-cart-summary-text-colour: var(--brandTextColour);
  --ecomm-cart-summary-category-colour: #{$msv-white};

  --ecomm-cart-item-bg: #{$msv-bleach-white};
}

.ecomm-order-summary {
  color: var(--ecomm-order-summary-colour);

  > .action {
    border: var(--ecomm-order-summary-action-border);
    color: var(--ecomm-order-summary-action-colour);
  }

  &.mobile {
    color: var(--ecomm-order-summary-colour);

    > .banner {
      > button.toggle {
        border: var(--ecomm-order-summary-toggle-border);
        color: var(--ecomm-order-summary-toggle-colour);
      }
    }
  }
}