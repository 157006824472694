:root {
    --ecomm-unsucessful-button-color: #9E7C10;
}

.ecomm-transaction-unsucessful {
    > .content {
        button {
            color: var(--ecomm-unsucessful-button-color);
        }
    }
}