$msv-white: #fff;
$msv-black: #000;
$msv-blue:    #0062af;
$msv-green: #08493F;
$msv-red: #901C32;
$msv-pink: #FCDFDF;
$msv-cyan: #0776d1;
$msv-yellow:#FFFFAC;
$msv-bleach-white: #f1e4cb;

$amber: #ffc000;
$romance: #F5EFE2;
$valentino: #373537;

$msv-grey-10:#ECF4F1;
$msv-gray-100: #F8F7F6;
$msv-gray-200: #E8E8E8;
$msv-gray-300: #D1D1D1;
$msv-gray-400: #6B727A;
$msv-gray-500: #616365;
$msv-gray-600: #444445;
$msv-gray-700: #B2AEAE;
$msv-gray-800: #9D9DAD;
$msv-gray-900: #1D1D1D;
$msv-gray-1000: #2D2D2D;

$msv-primary: #9e7c10;
$msv-primary-hover: #f1e4cb;
$msv-primary-shadow: #876808;
$msv-secondary: $valentino;
$msv-secondary-hover: #E1EFDC;
$msv-secondary-shadow: #d0bfa0;

$msv-text-color: $msv-white;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #F3F2F1;

$facebook-bg-color: #3C3C41;
$facebook-hover-color: #1D1D1D;
$microsoft-bg-color: #3C3C41;
$microsoft-hover-color: #1D1D1D;
$social-bg-color: #3C3C41;
$social-hover-color: #1D1D1D;

$msv-unfilled-rating-color: $msv-gray-800;

$brandPrimaryColor: #9e7c10;
$brandTextColour: #9e7c10;

:root {
    // Background
    --msv-bg-color: transparent;
    
    // Font
    --msv-font-primary-color: #{$brandPrimaryColor};
    --msv-font-secondary-color: #{$msv-green};
    --msv-font-description-product-color: #{$msv-black};

    --brandTextColour: #{$brandTextColour};

    // Borders
    --msv-border-color: #{$msv-gray-100};
   
    // Brand Accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-color-shadow: #{$msv-primary-shadow};
    --msv-accent-brand-alt-color: #{$msv-secondary};
    --msv-accent-brand-alt-color-shadow: #{$msv-gray-400};

    --brandPrimaryColor: #{$brandPrimaryColor};
    --brandSecondaryColor: #{$valentino};

    // Grey Black Accent 
    --msv-grey-black-color: #{$msv-secondary};
    --msv-grey-background-color: #{$msv-grey-10};
    --msv-black-background-color: #{$msv-black};

    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-accent-brand-color);
    --msv-flyout-bg-color: #{$msv-white};
}

.bg-bleach-white { background-color: #{$msv-bleach-white}; }