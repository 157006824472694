:root {
  --ecomm-side-bg: var(--brandPrimaryColor);
  --ecomm-side-colour: #{$msv-black};

  --ecomm-top-title-colour: #{$msv-bleach-white};

  --ecomm-filter-button-active-colour: var(--brandSecondaryColor);
}

.ecomm-side-filter {
  background-color: var(--ecomm-side-bg);
  color: var(--ecomm-side-colour);

  > .top {
    > .title { color: var(--ecomm-top-title-colour); }
  }

  > .filter {
    > button { 
      &.active { color: var(--ecomm-filter-button-active-colour); }
    }
  }
}