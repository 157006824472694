:root {
    --form-label-colour: #{$msv-white};
    --form-label-colour--req: #{$msv-white};
    --form-radio-colour: #{$msv-white};
    --form-border: 1px solid #{$msv-white};
    --form-subtotal-colour: #{$msv-white};
    --form-del-colour: #{$msv-white};
    --form-add-colour: #{$msv-white};
    --form-sub-hr-color: #{$msv-white};
    --form-sub-header-color: #{$msv-white};
}

.ecomm-purchase-form {
    &-table {
        .form-record {
            .form-details {
                &-item {
                    .form-label {
                        color: var(--form-label-colour);
                        &.required::after {
                            color: var(--ecomm-checkout-contact-label-colour--req);
                        }
                    }
                    .form-text {
                        color: var(--form-label-colour);
                    }
                    .radio-option {
                        color: var(--form-radio-colour);
                    }
                    &.bordered {
                        border-left: var(--form-border);
                        border-right: var(--form-border);
                    }
                    .subtotal {
                        color: var(--form-subtotal-colour);
                    }
                    .delete_row {
                        color: var(--form-del-colour);
                    }
                }
            }
        }
    }
    &-add {
        button {
            color: var(--form-add-colour);
        }
    }
    &-upload {
		button {
			background-color: var(--form-add-bg);
			border: var(--form-add-border);
			color: var(--form-add-colour);
			font-size: var(--form-add-size);
			padding: var(--form-add-padding);
			text-align: center;
			&::before { margin: var(--form-add-icon-margin); }
		}
	}
}