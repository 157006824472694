:root {
  --ecomm-page-navi-bg: #{$msv-secondary-shadow};
  --ecomm-page-navi-bg-active: var(--brandSecondaryColor);
  --ecomm-page-navi-font-colour-active: var(--brandTextColour);
}

.ecomm-page-navi {
  a { 
    background-color: var(--ecomm-page-navi-bg);

    &.active-link {
      background-color: var(--ecomm-page-navi-bg-active);
      color: var(--ecomm-page-navi-font-colour-active);
    }
  }
}