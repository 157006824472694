:root {
  --ecomm-prog-card-redirect-colour: #{$msv-white};
  --ecomm-prog-card-redirect-portal-colour: var(--brandSecondaryColor);
}

.ecomm-programme-card {
  > .content-wrapper {
    .redirect-wrapper {
      > .redirect {
        color: var(--ecomm-prog-card-redirect-colour);

        &.portal {
          color: var(--ecomm-prog-card-redirect-portal-colour);
        }

        &:hover {
          color: var(--ecomm-prog-card-redirect-colour);

          &.portal {
            color: var(--ecomm-prog-card-redirect-portal-colour);
          }
        }
      }
    }
  }
}