:root {
  --ecomm-main-menu-ul-colour: var(--brandSecondaryColor);

  --ecomm-main-menu-account-sign-colour: var(--brandSecondaryColor);

  --ecomm-main-menu-account-initial-colour: #{$msv-white};

  --ecomm-sub-menu-q-label-colour: var(--brandSecondaryColor);

  --ecomm-sub-menu-q-link-colour: #{$msv-white};
  --ecomm-sub-menu-q-link-colour-portal: var(--brandSecondaryColor);

  --ecomm-sub-menu-q-divider-colour: var(--brandSecondaryColor);

  --ecomm-sub-menu-lang-border: 1px solid #{$msv-white};
  --ecomm-sub-menu-lang-dd-colour: #{$msv-white};
}

.ecomm-main-menu.desktop {
  > .navbar {
    .navbar-nav {
      > .main-link {
        &.nav-item.dropdown {
          > .dropdown-menu {
            > .dropdown-content {
              ul {
                &::before { color: var(--ecomm-main-menu-ul-colour); }
              }
            }
          }
        }
      }

      > .actions-wrapper {
        > .action {
          &--sign-in { color: var(--ecomm-main-menu-account-sign-colour); }
        }

        > .account {
          > a.nav-link { color: var(--ecomm-main-menu-account-initial-colour); }
        }
      }
    }
  }

  > .sub-menu {
    > .quicklinks-wrapper {
      > .label { color: var(--ecomm-sub-menu-q-label-colour); }

      > .quicklink-item {
        > a { 
          color: var(--ecomm-sub-menu-q-link-colour); 

          &.portal { 
            color: var(--ecomm-sub-menu-q-link-colour-portal);

            &:hover { color: var(--ecomm-sub-menu-q-link-colour-portal); }
          }

          &:hover { color: var(--ecomm-sub-menu-q-link-colour); }
        }

        > .divider { color: var(--ecomm-sub-menu-q-divider-colour); }
      }
    }

    > .language-wrapper .dropdown {
      border: var(--ecomm-sub-menu-lang-border);

      > button { 
        color: var(--ecomm-sub-menu-lang-dd-colour); 

        &:hover { color: var(--ecomm-sub-menu-lang-dd-colour); }
      }
    }
  }
}