:root {
  --hero-banner-button-colour-first: #{$msv-white};
}

.ecomm-hero-banner {
  &-actions {
    a {
      &.first-button { color: var(--hero-banner-button-colour-first); }
    }
  }
}