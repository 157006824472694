:root {
  --ecomm-report-navi-bg: #{$msv-bleach-white};
  --ecomm-report-navi-bg-active: var(--brandPrimaryColor);

  --ecomm-report-navi-font-colour: #{$msv-gray-900};
  --ecomm-report-navi-font-colour-active: var(--brandSecondaryColor);
}

.ecomm-navi {
  button {
    background-color: var(--ecomm-report-navi-bg);
    color: var(--ecomm-report-navi-font-colour);

    &.active {
      background-color: var(--ecomm-report-navi-bg-active);
      color: var(--ecomm-report-navi-font-colour-active);
    }
  }
}