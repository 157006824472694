:root {
  --template-link-first-colour: #{$msv-white};
}

.ecomm-content-template {
  &-5 {
    .info-container {
      .actions-container {
        > a:first-child { color: var(--template-link-first-colour); }
      }
    }
  }
}