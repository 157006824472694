:root {
  --ecomm-event-desc-button-colour-second: #{$msv-white};
}

.ecomm-event-description {
  > .content {
    > .actions {
      a {
        &.second-button { color: var(--ecomm-event-desc-button-colour-second); }
      }
    }
  }
}