:root {
  --ecomm-checkout-background-color: #{$msv-bleach-white};
  --ecomm-checkout-text-colour: #{$valentino};
  
  --ecomm-checkout-information-text-color: #{$valentino};

  --ecomm-checkout-item-image-border: 1px solid #{$msv-bleach-white};

  --ecomm-checkout-qr-download-width: 12rem;

  --ecomm-checkout-summary-hr-color: #{$valentino};

  --ecomm-checkout-consent-main-color: #{$msv-red};

  --ecomm-checkout-button-complete-background-color: var(--brandPrimaryColor);
  --ecomm-checkout-button-complete-color: {$msv-white};

  --ecomm-checkout-action-button-width: 12rem;

  --ecomm-checkout-action-back-proceed-colour: #{$msv-white};
}

.ms-checkout {
  color: var(--ecomm-checkout-text-colour);

  &__title {
    color: #333333;
  }

  &__main {
    .ms-checkout__terms-and-conditions {
      color: var(--ecomm-checkout-information-text-color);
    }

    .ecomm-qr {
      .download-wrapper {
        > button { @include ecomm-primary-button(true, 'GP', var(--ecomm-checkout-qr-download-width)); }
      }
    }
  }

  &__main-control {
    .ms-checkout__btn-place-order {
      background-color: #9E7C10;
      color: #FFFFAC;
      border: 1px solid #c7c7cc;
    }

    .ms-checkout__btn-place-order:hover {
      background-color: #9E7C10;
      border: 1px solid #c7c7cc;
    }

    .ms-checkout__btn-place-order:active {
      background-color: #9E7C10;
      border: 1px solid #c7c7cc;
    }

    .ms-checkout__btn-keep-shopping {
      background-color: white;
      border: 1px solid #c7c7cc;
      color: #901C32;
    }
  }

  &__form {
    &-actions {
      > .back { @include ecomm-primary-button(false, 'GP', var(--ecomm-checkout-action-button-width)); }

      > .proceed { 
        @include ecomm-primary-button(true, 'GP', var(--ecomm-checkout-action-button-width)); 

        &:hover, &:active { color: var(--ecomm-checkout-action-back-proceed-colour); }
      }
    }

    &-heading { color: var(--ecomm-checkout-consent-main-color); }

    &-contact {
      .form-consent {

        &-sub {
          color: #9D8D90;
        }
      }
    }

    .ms-checkout__btn-place-order {
      background-color: #9E7C10;
      color: #ffffff;
    }

    .ms-checkout__btn-place-order:hover {
      background-color: #9E7C10;
      color: #ffffff;
    }

    .ms-checkout__btn-place-order:active {
      background-color: #9E7C10;
      color: #ffffff;
    }
  }

  &__side {
    background-color: var(--ecomm-checkout-background-color);

    &__line {
      border-top: 1px solid var(--ecomm-checkout-summary-hr-color);
    }

    .ms-checkout__line-items {

      .ms-checkout__line-items-delivery-group {
        
        .ms-checkout__line-items-list {

          .ms-checkout__line-item {

            .msc-cart-line {

              &__content {

                .msc-cart-line__product {

                  &-title {
                    color: var(--ecomm-checkout-text-colour);
                  }
                }

                .msc-cart-line__product-price {
                  
                  .msc-price {

                    .msc-price__actual {
                      color: var(--ecomm-checkout-text-colour);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__guided-card {
    // border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
    color: #333333;

    &.visted,
    &.active {
      // border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
      color: var(--msv-checkout-address-heading-font-color);
    }

    &-title {
      color: #901C32;
    }
  }
}
