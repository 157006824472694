:root {
    --ecomm-history-filter-button-active-colour: var(--brandSecondaryColor);

    --ecomm-history-top-color: var(--brandSecondaryColor);
  }

.ecomm-history-filter {
  > .top {
    border-left: transparent;
    border-right: transparent;
    border-style: dashed;
    border-top: transparent;
    color: var(--ecomm-history-top-color);
  }
}