:root {
  --ecomm-popup-btn-bg: var(--brandPrimaryColor);
  --ecomm-popup-btn-colour--stay: var(--brandTextColour);
  --ecomm-popup-btn-colour--go: #{$msv-white};
}

.ecomm-popup {
  &-button {
    &-stay, &-stay:hover, &-stay:active, &-stay:visited {
      color: var(--ecomm-popup-btn-colour--stay);
    }

    &-go, &-go:hover, &-go:active, &-go:visited {
      background-color: var(--ecomm-popup-btn-bg);
      color: var(--ecomm-popup-btn-colour--go);
    }

    &-go-portal, &-go-portal:hover, &-go-portal:active, &-go-portal:visited {
      background-color: var(--ecomm-popup-btn-bg);
      color: var(--ecomm-popup-btn-colour--go);
    }
  }
}