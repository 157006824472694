$cart-lines-item-background-color: #F1E4CB;

// Style presets
:root {
  --msv-cart-heading-font-color: var(--msv-font-primary-color);

  --btrts-shopping-cart-badge-font-colour: var(--msv-accent-brand-color);
  --btrts-shopping-cart-checkout-button-border-colour: var(--msv-font-primary-color);
  --btrts-shopping-cart-checkout-button-font-colour: var(--msv-font-primary-color);

  --ecomm-cart-unavailable-line-accordion-margin: 0;

  --ecomm-cart-unavailable-line-header-color: #f1e4cb;
}

.ecomm-cart {
  &-unavailable-line {
    &-accordion {
      margin: var(--ecomm-cart-unavailable-line-accordion-margin);
    }
  }
}

.ms-cart {

  .msc-cart-line {
    color: #333333;
  }

  .msc-cart-lines-item {
    background-color: $cart-lines-item-background-color;

    &-product {
      color: #333333;

      &-category {
          color: #333333;
      }

      &-actions {

        a {
          color: #333333;
        }

        a:hover {
            color: #333333;
        }

        a:visited {
            color: #333333;
        }
      }
    }

    &-names {
      color: #333333;
    }

    &-price {
      color: #333333;
    }
  }
}

@media screen and (max-width: $msv-breakpoint-m) {
  .ms-cart {

    .msc-cart-lines-item {
      background-color: $cart-lines-item-background-color;

      &-product {
        color: #333333;
  
        &-category {
            color: #333333;
        }
  
        &-actions {
  
          a {
            color: #333333;
          }
  
          a:hover {
              color: #333333;
          }
  
          a:visited {
              color: #333333;
          }
        }
      }
  
      &-names {
        color: #333333;
      }
  
      &-price {
        color: #333333;
      }

      .msc-cart-line {
        color: #333333;
      }
    }
  }
}

.msc-cart-remove-item {
    color: #333333;
}