:root {
  --ecomm-product-list-price-font-colour: var(--brandSecondaryColor);

  --ecomm-product-list-pg-active-bg: #{$msv-bleach-white};
}

.ecomm-product-list {
  > li {
    .info-container {
      p:last-child { color: var(--ecomm-product-list-price-font-colour); }
    }
  }

  &-pagination {
    li {
      &.active { background-color: var(--ecomm-product-list-pg-active-bg); }
    }
  }
}