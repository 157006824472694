:root {
  --ecomm-mobile-menu-account-link-colour: #{$msv-white};

  --ecomm-mobile-menu-q-label-colour: var(--brandSecondaryColor);

  --ecomm-mobile-menu-sitelinks-dd-ul-colour: var(--brandSecondaryColor);
}

.ecomm-main-menu.mobile {
  .navbar {
    > .header-wrapper {
      > .actions-wrapper {
        > .account {
          > a.nav-link {
            color: var(--ecomm-mobile-menu-account-link-colour);
          }
        }
      }
    }

    &-nav {
      > .quicklinks-wrapper {
        > .label { color: var(--ecomm-mobile-menu-q-label-colour); }
      }

      > .sitelinks-wrapper {
        > .main-link {
          > .dropdown-content {
            > ul {
              &::before { color: var(--ecomm-mobile-menu-sitelinks-dd-ul-colour); }
            }
          }
        }
      }
    }
  }
}