:root {
  --ecomm-product-card-not-available-colour: #{$msv-white};
}

.ecomm-product-card {
  &.not-available {
    .img-container {
      p { 
        color: var(--ecomm-product-card-not-available-colour);
      }
    }
  }
}