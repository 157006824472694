:root {
  --ecomm-checkout-background-color: #F1E4CB;
  --ecomm-checkout-text-colour: #333333;
  
  --ecomm-checkout-information-text-color: #333333;

  --ecomm-checkout-item-image-border: 1px solid #D3D3EB;

  --ecomm-checkout-summary-hr-color: #333333;

  --ecomm-checkout-consent-main-color: #901C32;

  --ecomm-checkout-action-color: #B4433B;
  --ecomm-checkout-button-complete-background-color: white;
  --ecomm-checkout-button-complete-color: #9E7C10;
}

.ms-checkout {
  color: var(--ecomm-checkout-text-colour);

  &__title {
    color: #333333;
  }

  &__main {
    .ms-checkout__terms-and-conditions {
      color: var(--ecomm-checkout-information-text-color);
    }
  }

  &__payment-fail {

    &-content {
      &-action {
        color: var(--ecomm-checkout-action-color);
      }
    }

    .ms-checkout__btn-place-order {
      background-color: var(--ecomm-checkout-button-complete-background-color);
      color: var(--ecomm-checkout-button-complete-color);
    }

    .ms-checkout__btn-place-order:hover {
      background-color: var(--ecomm-checkout-button-complete-background-color);
    }

    .ms-checkout__btn-place-order:active {
      background-color: var(--ecomm-checkout-button-complete-background-color);
    }
  }

  &__side {
    background-color: var(--ecomm-checkout-background-color);

    &__line {
      border-top: 1px solid var(--ecomm-checkout-summary-hr-color);
    }

    .ms-checkout__line-items {

      .ms-checkout__line-items-delivery-group {
        
        .ms-checkout__line-items-list {

          .ms-checkout__line-item {

            .msc-cart-line {

              &__content {

                .msc-cart-line__product {

                  &-title {
                    color: var(--ecomm-checkout-text-colour);
                  }
                }

                .msc-cart-line__product-price {
                  
                  .msc-price {

                    .msc-price__actual {
                      color: var(--ecomm-checkout-text-colour);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__guided-card {
    // border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
    color: #333333;

    &.visted,
    &.active {
      // border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
      color: var(--msv-checkout-address-heading-font-color);
    }

    &-title {
      color: #901C32;
    }
  }
}
