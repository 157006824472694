:root {
  --ecomm-upcoming-programmes-nav-colour: var(--brandSecondaryColor);
}

.ecomm-upcoming-programmes {
  > .top {
    > .nav {
      > a {
        color: var(--ecomm-upcoming-programmes-nav-colour);

        &:hover { color: var(--ecomm-upcoming-programmes-nav-colour); }
      }
    }
  }
}