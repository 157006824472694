:root {
  --sutra-bg-hover: #{$valentino};

  --sutra-action-colour-active: #{$msv-white};
}

.ecomm-event {
  &.active {
    .ecomm-event-action button { color: var(--sutra-action-colour-active); }
  }

  &:hover { background-color: var(--sutra-bg-hover); }
}