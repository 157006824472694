:root {
  --user-profile-submit-bg: var(--brandPrimaryColor);
  --user-profile-submit-colour: #{$msv-white};
}

.ecomm-user-profile {
  > form {
    > .actions {
      > .submit { 
        background-color: var(--user-profile-submit-bg); 
        color: var(--user-profile-submit-colour);
      }
    }
  }
}