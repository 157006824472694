:root {
  --job-card-nav-font-colour: var(--brandPrimaryColor);
}

.ecomm-job-card {
  &-nav {
    color: var(--job-card-nav-font-colour);

    &:hover { color: var(--job-card-nav-font-colour); }
  }
}