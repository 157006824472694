:root {
  --ecomm-order-summary-background-color: #{$msv-bleach-white};

  --ecomm-order-summary-divider-border: 1px solid var(--brandSecondaryColor);

  --ecomm-order-summary-header-color: var(--brandSecondaryColor);

  --ecomm-order-summary-item-content-color: var(--brandSecondaryColor);
  --ecomm-order-summary-item-content-color: var(--brandSecondaryColor);

  --ecomm-order-total-color: var(--brandSecondaryColor);
}

.ecomm-order-confirm-summary {
  > .summary {
    background-color: var(--ecomm-order-summary-background-color);

    p.header { color: var(--ecomm-order-summary-header-color); }

    > .item {
      > .item-content {
        .item-name { color: var(--ecomm-order-summary-item-content-color); }

        .item-price { color: var(--ecomm-order-summary-item-content-color); }
      }
    }

    hr.line-end { border-top: var(--ecomm-order-summary-divider-border); }

    > .total { color: var(--ecomm-order-total-color); }
  }
}