:root {
    --ecomm-order-button-color: #9E7C10;
}

.overlay-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #edebe9ef;
}

.overlay-spinner {
    color: var(--ecomm-order-button-color);
    margin-bottom: 2rem;
}

.overlay-text {
    color: #333333;
}

.ecomm-order-confirmation {
    >.content {
        button {
            color: var(--ecomm-order-button-color);
        }
    }
}