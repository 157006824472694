// :root {
//   --buybox-title-font-color: #333333;
//   --buybox-price-font-color: #333333;
// }
// .ms-buybox {
//   &__product-title {
//     color: var(--buybox-title-font-color);
//   }
//   .msc-price__actual {
//     color: var(--buybox-price-font-color);
//   }
//   .ms-buybox__add-to-cart-container {
//     .ms-buybox__button1 {
//       color: #9E7C10;
//     }
//     .ms-buybox__button2 {
//       background-color: #9E7C10;
//       color: #C7C7CC;
//     }
//   }
// }
.popup-card {
    background-color: #{$msv-white};
    text-align: center;
    max-width: 520px;
    padding: 1rem
}

.popup-header {
    color: var(--ecomm-buybox-product-title-colour);
    // font-size: var(--ecomm-buybox-product-price-size);
    font-weight: var(--ecomm-buybox-action-weight);
}

.popup-subtext {
    color: var(--ecomm-buybox-product-summary-colour);
    // font-size: var(--ecomm-buybox-product-price-size);
    // font-weight: var(--ecomm-buybox-action-weight);
    padding: 1rem 1rem;
}

.overlay-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #3a3937ef;
}

.signin-popup {
    color: var(--brandSecondaryColor);
    background-color: var(--brandPrimaryColor);
    padding: .5rem 1rem;
    border: 1px solid #B2AEAE;
}

.dismiss-popup {
    color: var(--brandPrimaryColor);
    padding: .5rem 1rem;
    border: 1px solid #B2AEAE;
}